import React from "react";
import Header from "../components/header";

import {Helmet} from "react-helmet";
import "../styles/index.css";
import {StaticImage} from "gatsby-plugin-image";

function Index() {
  return (
    <main>
      <Helmet>
        <title>RideAmigos Admin Interface Redesign</title>
      </Helmet>

      <Header/>

      <section class="bg-rideamigos">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 order-2 order-lg-1">
              <h1>RideAmigos Admin Interface Redesign</h1>
              <small>
                design / prototyping / user testing
              </small>

              <p class="lead">
                Redesign to allow Admins to easily navigate, manage and report on users and programs in any network
              </p>
            </div>
            <div class="col-lg-6 order-1 order-lg-2">

              <StaticImage
                  src="../images/card-thumb-admin-interface.png"
                  alt="Indiana University logo"
                  placeholder="blurred"
                  className="img-fluid"
              />

            </div>
          </div>
        </div>
      </section>

      {/* <!-- content --> */}

      <section>
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <h4>Problem</h4>
              <p class="">
                Administrators of the RideAmigos platform manage permissions and programs for their commuters by
                grouping them into simple networks and sub-networks. New features like cascading permissions and the
                ability to add sub-administrators and new uses of networks to represent entire companies or regions made
                navigation very difficult and error-inducing for Adminstrators.
              </p>

            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <h4>The Solution</h4>
              <p class="">
                The solution was to redesign the entire Admin interface, beginning with user research, prototyping
                and testing. The end result
              </p>

              <ul className="">
                <li>

                  Ensures that an Administrator can easily find, manage and report on users and programs in any network
                </li>
                <li>Enables our company to enter a new market of large, multi-organizational customers</li>
              </ul>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <h4>My Role</h4>
              <p class="">
                My role was to guide this project from initial discovery to completion. I conducted the user research
                and testing, the design and prototyping, and collaborated with our developers on the final
                specifications.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="bg-light">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 order-2 order-lg-2">
              <h2>Research</h2>

              <figure className="figure">

                <StaticImage
                    src="../images/admin-research-walkthrough.png"
                    alt="Cognitive walkthrough of interface"
                    placeholder="blurred"
                    className="figure-img img-fluid"
                />


                <figcaption className="figure-caption text-center">
                  A remote cognitive walkthrough
                </figcaption>
              </figure>

              <ul className="">
                <li>
                  A series of remote cognitive walkthroughs with Admin users to understand their use of the system and
                  Network-related pain points
                </li>
                <li>
                  Deep dives with our dev team into the technical architecture of our Network data and transaction
                  models
                </li>
                <li>
                  Meetings with our Business Development and Technology leadership about future use cases for Networks
                </li>
              </ul>
            </div>

            <div className="col-lg-6 order-2 order-lg-2">
              <h2>Insights</h2>


              <div id="insightCarousel" className="carousel slide" data-ride="carousel" data-interval="false">
                <ol className="carousel-indicators my-4">
                  <li data-target="#insightCarousel" data-slide-to="0" className="active"></li>
                  <li data-target="#insightCarousel" data-slide-to="1"></li>
                  {/*<li data-target="#insightCarousel" data-slide-to="2"></li>*/}
                </ol>
                <div className="carousel-inner">
                  <div className="carousel-item active">

                    <StaticImage
                        src="../images/admin-all-users.png"
                        alt="..."
                        placeholder="blurred"
                        className="d-block w-100"
                    />

                    <div className="carousel-caption mb-4">
                      <p>Early sketch showing how all Admin users could use the same interface</p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <a href="#admin-ux-drawing">
                      <StaticImage
                          src="../images/admin-ux-drawing.png"
                          alt="..."
                          placeholder="blurred"
                          className="d-block w-100"
                      />
                    </a>
                    <div className="carousel-caption mb-4">
                      <p>Working sketch from deep dive into data models and the resulting implications for the interface</p>
                    </div>
                  </div>

                </div>
                <a className="carousel-control-prev" href="#insightCarousel" role="button" data-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#insightCarousel" role="button" data-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>


              <ul className="">
                <li>
                  Our Business Development and Technology leadership identified the eventual goal of using the new
                  navigation for all Admin functions
                </li>

                <li>
                  A deep dive into our data models revealed a non-strict hierarchy, making standard hierarchical (e.g.,
                  breadcrumbs) navigation difficult or impossible
                </li>
                <li>
                  Administrators found it difficult to keep track of their network context, often leading to errors.
                  They also found it difficult to see aggregate network data like user counts, CO2 savings, or rewards.
                </li>

              </ul>
            </div>

          </div>
        </div>
      </section>


      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-2 order-lg-2">
              <h2>Prototypes</h2>


              <div id="prototypeCarousel" className="carousel slide" data-ride="carousel" data-interval="false">
                <ol className="carousel-indicators my-4">
                  <li data-target="#prototypeCarousel" data-slide-to="0" className="active"></li>
                  <li data-target="#prototypeCarousel" data-slide-to="1"></li>
                  {/*<li data-target="#prototypeCarousel" data-slide-to="2"></li>*/}
                </ol>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src="/images/admin-networks-prototype.gif" className="d-block w-100" alt="..."></img>
                    <div className="carousel-caption mb-4">
                      <p>The clickable prototype allowed us to rapidly test the interface with Admin users, identify
                        issues, iterate and repeat the process.</p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <StaticImage
                        src="../images/admin-prototype.png"
                        alt="..."
                        placeholder="blurred"
                        className="d-block w-100"
                    />
                    <div className="carousel-caption mb-4">
                      <p>Prototypes were built in Sketch and hosted on Sketch Cloud to facilitate review and commenting
                        by stakeholders.</p>
                    </div>
                  </div>

                </div>
                <a className="carousel-control-prev" href="#prototypeCarousel" role="button" data-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#prototypeCarousel" role="button" data-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>

              {/*             <figure className="figure">
                <img
                  src="/static/admin-prototype.png"
                  className="figure-img img-fluid"
                  alt=""
                ></img>
                <figcaption className="figure-caption text-center">
                  Prototypes, Artboards and Collaborators in Sketch Cloud
                </figcaption>
                <br/>
                <p className="">
                  Prototypes were built in Sketch and hosted on Sketch Cloud to facilitate review and commenting by stakeholders.
                </p>
              </figure>*/}


            </div>

            <div className="col-lg-6 order-2 order-lg-2">
              <h2>Testing and Iteration</h2>
              <figure className="figure">

                <StaticImage
                    src="../images/admin-mb-left.png"
                    alt="Cognitive walkthrough of interface"
                    placeholder="blurred"
                    className="figure-img img-fluid"
                />

                <figcaption className="figure-caption text-center">
                  The new interface is now live for all RideAmigos customers
                </figcaption>

                <br/>
                <p className="">
                  The updated interface was first implemented in May of 2020. Testing with stakeholders and iterations
                  are ongoing.

                </p>
              </figure>

            </div>


          </div>
        </div>
      </section>


      <div class="lightbox-target" id="admin-ux-drawing">
        <img src="/static/admin-ux-drawing.png"/>
        <a class="lightbox-close" href="#"></a>
      </div>

      <div class="lightbox-target" id="research-animated">
        <img src="/static/mobile-trip-planning-research-animated.gif"/>
        <a class="lightbox-close" href="#"></a>
      </div>

      <div class="lightbox-target" id="insights">
        <img src="/static/mobile-trip-planning-insights.png"/>
        <a class="lightbox-close" href="#"></a>
      </div>

      <div class="lightbox-target" id="prototypes">
        <img src="/static/mobile-trip-planning-prototypes.png"/>
        <a class="lightbox-close" href="#"></a>
      </div>

      <div class="lightbox-target" id="testing">
        <img src="/static/mobile-trip-planning-testing.png"/>
        <a class="lightbox-close" href="#"></a>
      </div>

      <div class="lightbox-target" id="conclusions">
        <img src="/static/mobile-trip-planning-conclusions.png"/>
        <a class="lightbox-close" href="#"></a>
      </div>
    </main>
  );
}

export default Index;
